@import "../../../variables";
@import "../../../../components/headers";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "flexslider/flexslider";

.homepage-container {
    max-width: 1800px;
    margin: 0 auto;
}

.herobanner-container {
    &.flexslider {
        margin: 0;
        border: none;
        border-radius: none;
    }

    .flex-viewport {
        overflow: hidden;
        position: relative;
    }

    .flex-control-nav {
        bottom: 15px;

        @include media-breakpoint-down(lg) {
            bottom: 10px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .flex-control-paging li a {
        background: rgba(255, 255, 255, 0.35);
        width: 15px;
        height: 15px;
        box-shadow: none;

        &:hover {
            background: $white;
        }

        &.flex-active {
            background: $white;
        }
    }

    .hero-banner-content {
        background-size: cover;
        background-position: center center;
        padding-bottom: 38%;

        &[data-video-id] {
            position: relative;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 102.5%;
            }
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }

        .hero-banner-inner {
            @include media-breakpoint-up(lg) {
                position: absolute;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
            }

            .hero-image {
                display: none;
                @include media-breakpoint-down(md) {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .mobile-callout-background {
                background-size: cover;
                background-position: center center;
            }

            .hero-callout {
                background-color: rgba(0, 0, 0, 0.65);
                color: $white;
                margin-left: 40px;
                padding: 60px 0;
                width: 595px;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;

                @include media-breakpoint-down(lg) {
                    width: 436px;
                    padding: 50px 0;
                }

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    padding: 48px 0;
                    width: 100%;
                    background-color: $slate;
                }

                @include media-breakpoint-down(sm) {
                    padding: 25px 0;
                }

                .main-text {
                    @include h2();

                    line-height: 1;
                    margin: 0;
                    margin-bottom: 12px;
                    text-transform: uppercase;

                    @include media-breakpoint-down(lg) {
                        @include h3();
                    }
                }

                .sub-text {
                    padding-bottom: 11px;

                    @include media-breakpoint-down(md) {
                        padding-bottom: 0;
                    }
                }

                .two-button-container {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    gap: 10px 25px;

                    a {
                        flex: 2;

                        button {
                            width: 100%;
                            white-space: nowrap;
                        }
                    }
                }

                .btn.btn-secondary {
                    color: $white;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                // mobile/tablet section of isml
                .second-hero-button .btn.btn-secondary {
                    margin-left: 0;
                    margin-top: 15px;
                }

                .hero-slider-arrow.icon-navigation-left,
                .hero-slider-arrow.icon-navigation-right {
                    min-width: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                    cursor: pointer;
                    filter: invert(1);
                    border: none;
                    background-color: transparent;

                    &:focus {
                        outline: none;
                    }

                    /* stylelint-disable selector-pseudo-class-no-unknown */
                    &:focus-visible {
                        outline: 2px solid $slate;
                        outline-offset: 2px;
                    }

                    &:disabled {
                        cursor: unset;
                        opacity: 0;
                    }

                    @include media-breakpoint-down(sm) {
                        min-width: 45px;
                    }
                }

                .content {
                    flex-direction: column;
                    width: 100%;

                    @include media-breakpoint-only(md) {
                        padding: 0 60px;
                    }
                }
            }
        }
    }
}
